import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week starts today`}</em></p>
    <p>{`DB Jump Squats 12-12-12`}</p>
    <p>{`Single Leg RDL 6-6-6/leg`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`DB Step Ups, 24/20″ (25/15’s, RX+=35/25’s, per leg)`}</p>
    <p>{`Toes thru Rings`}</p>
    <p>{`Squats Holding Plate (45/25)`}</p>
    <p>{`Burpees Onto Plate (45/25)`}</p>
    <p>{`for time.`}</p>
    <p><em parentName="p">{`*`}{`compare to 8/21/17`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      